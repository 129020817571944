import React, {useCallback, useEffect, useRef, useState} from 'react';
import {SzInput} from "@suezenv/react-theme-components";
import {IRequestFieldValues} from "../../../types";
import {debounce} from "lodash";
import {SzInputProps} from "@suezenv/react-theme-components/build/components/SzInput/SzInput";
import "./index.scss";
import OutsideClickHandler from 'react-outside-click-handler';

interface ISzSelectAutocompleteProps extends SzInputProps {
    dataFetcher: Function,
    fetcherArgs: any[],
    inputStyle: any,
    selectStyle: any,
    forceUppercase: boolean
    parentSync: Function | {
        setState: Function,
        propName: string,
    },
}

const ownProps: string[] = [
    'fetcherArgs',
    'dataFetcher',
    'listStyle',
    'selectStyle',
    'forceUpperCase',
    'parentSync',
];

/**
 * @param {ISzSelectAutocompleteProps} props
 */
export default function SzSelectAutocomplete(props: any): JSX.Element {
    const debounceDelay: number = 600;
    const inputRef = useRef<any | null>(null);
    const [showResult, setShowResult] = useState<boolean>(false);
    const [inputSearchValue, setInputSearchValue] = useState<string>('');
    const [proposals, setProposals] = useState<IRequestFieldValues>({count: 0, data: []} as IRequestFieldValues);
    const {
        fetcherArgs,
        dataFetcher,
        inputStyle,
        selectStyle,
        parentSync,
        forceUpperCase,
        value,
    } = props;

    /**
     * Creates only one autocomplete process per user input sequence.
     */
    const debounceAutocomplete = useCallback(
        debounce(async (value: string): Promise<void> => {
            if (value !== '') {
                setProposals(await dataFetcher(...[value, ...fetcherArgs]));
            }
        }, debounceDelay),
        [],
    );

    const handleOnChange = (e: any): void => {
        let value = e.target.value;

        if (true === forceUpperCase) {
            value = value.toUpperCase();
        }

        resetAutocomplete(value);
        debounceAutocomplete(value)
    };

    const autoCompleteClickHandle = (value: string): void => {
        resetAutocomplete(value);
    };

    const resetAutocomplete = (value: string = '', display: boolean = false) => {
        setShowResult(display);
        setInputSearchValue(value);
        parentStateSynchronization(value);
    }

    /**
     * @return {SzInputProps}
     */
    const getInputProps = (): any => {
        const inheritProps = {...props};

        ownProps.forEach((prop: string): void => {
            if (prop in inheritProps) {
                delete inheritProps[prop];
            }
        });

        return inheritProps as SzInputProps;
    }

    /**
     * Updates prop state from parent.
     */
    const parentStateSynchronization = (value: any): void => {
        // Unable to synchronize.
        if (!parentSync) {
            return;
        }

        try {
            // Synchronizes using classic prop state (functional component).
            if (parentSync instanceof Function) {
                parentSync(value);

                return;
            }

            // Synchronizes using "this.setState()" (class component).
            const {setState, propName} = parentSync;

            if (setState instanceof Function && typeof propName === 'string') {
                setState({
                    [propName]: value,
                });
            }
        } catch (exception) {
            // Error caught thus the main thread is not blocked.
        }
    }

    /**
     * Processes effects on the autocomplete list.
     */
    useEffect((): void => {
        if (!showResult && 0 < proposals.count) {
            setShowResult(true);
        }
    }, [proposals]);

    /**
     * Changes inputSearchValue when the parent change the state of the value.
     */
    useEffect((): void => {
        if (value === '') {
            setInputSearchValue(value);
        }
    }, [value]);

    return (
        <div className={'SzSelectAutocomplete'}>
            <OutsideClickHandler
                onOutsideClick={(): void => {
                    setShowResult(false);
                }}
            >
                <SzInput
                    {...getInputProps()}
                    onChange={handleOnChange}
                    value={inputSearchValue}
                    style={inputStyle}
                    ref={inputRef}
                />
                {showResult && (
                    <div
                        className="autcomplete-proposals position-absolute list-group overflow-auto mh-100"
                        style={{
                            width: inputRef?.current?.offsetWidth ?? 0,
                            ...selectStyle,
                        }}
                    >
                        {(Object.entries(proposals.data)?.map(([index, value]) => {
                            value = forceUpperCase ? value.toUpperCase() : value;
                            return (
                                <button
                                    key={index}
                                    type="button"
                                    className="list-group-item list-group-item-action"
                                    onClick={() => {
                                        autoCompleteClickHandle(value);
                                    }}
                                >
                                    {value}
                                </button>
                            );
                        }))}
                    </div>
                )}
            </OutsideClickHandler>
        </div>
    );
}
